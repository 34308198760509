import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  date?: string
  image?: ImageProps
  languageCode: string
  reference?: string
  title?: string
}

export const Press = memo(function Press({
  cta,
  date,
  reference,
  image,
  languageCode,
  title,
}: Props) {
  return (
    <Container>
      <Background>{image ? <Image {...image} /> : null}</Background>

      <Wrapper>
        {reference ? <Refercence>{reference}</Refercence> : null}

        {title ? <Title>{title}</Title> : null}

        {date ? (
          <Date>
            {typeof window !== 'undefined'
              ? new window.Date(date).toLocaleDateString(languageCode, {
                  year: 'numeric',
                  month: 'long',
                })
              : null}
          </Date>
        ) : null}

        {cta ? <CTA {...cta} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 1.5rem);
  margin: 3.75rem 1.5rem 0 0;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 2.875rem;
    margin-right: 0;
  }
`

const Background = styled.div`
  aspect-ratio: 1.5;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  margin-top: 2.25rem;
  padding: 0 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
    padding: 0;
  }
`

const Refercence = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.625rem;

  @media (max-width: 1023px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`

const Date = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  margin-top: 2.25rem;
`
