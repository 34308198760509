import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Press, Props as PressProps } from './Press'

export interface Props {
  description?: string
  items: PressProps[]
}

export const PressList = memo(function PressList({
  description,
  items,
}: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {description ? (
        <Fade bottom distance="3.75rem">
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}

      <List row wrap>
        {items
          .sort(
            (a: any, b: any) =>
              new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          .map((item, index) => (
            <Press key={index} {...item} />
          ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6rem 10vw 11.4375rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem 5.625rem;
  }
`

const Description = styled.div`
  max-width: 47.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
`

const List = styled(FlexBox)`
  margin-right: -1.5rem;
  text-align: left;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`
